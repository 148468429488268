import React, { useState, useEffect } from "react";
import Logo from "Assets/logo.png";
import Styles from "./Styles.module.css";
import Button from "Components/Button";
import { FiUser, FiLock } from "react-icons/fi";
import { useNavigate } from "react-router";
import { Link, NavLink } from "react-router-dom";
import Logo2 from "Assets/logo4.png";
interface MenuItemProps {
    label: string;
    LinkTo?: string;
}
interface ButtonTypes {
    Title: string;
    onClick: (event: React.MouseEvent<HTMLButtonElement>) => void;
    Theme?: "Dark" | "Light";
    icon?: React.ReactNode;
    fontWeight?: number | string;
}
interface MenuDropdownProps {
    label: string;
    isOpen: boolean;
    onClick: () => void;

    items: MenuItemType[];
}

interface MenuItemType {
    label: string;
    subItems?: MenuItemType[];
    LinkTo?: string;
}

const Menu: React.FC = () => {
    const [menuActive, setMenuActive] = useState<boolean>(false);
    const [mobileMenuActive, setMobileMenuActive] = useState<boolean>(false);
    const Navigate = useNavigate();
    const [openedDropdowns, setOpenedDropdowns] = useState<number[]>([]);

    useEffect(() => {
        const bodyClass = mobileMenuActive
            ? "mobile-menu-active hide-scrolling"
            : "";
        document.body.className = bodyClass;

        return () => {
            document.body.className = "";
        };
    }, [mobileMenuActive]);

    const toggleMenu = () => {
        setMenuActive(!menuActive);
        setMobileMenuActive(!mobileMenuActive);
    };

    const handleDropdownClick = (index: number) => {
        setOpenedDropdowns((prevOpenedDropdowns) =>
            prevOpenedDropdowns.includes(index)
                ? prevOpenedDropdowns.filter((i) => i !== index)
                : [...prevOpenedDropdowns, index]
        );
    };

    return (
        <div className={Styles.wrapper}>
            <div className={Styles.subwrapper}>
                <Link to={"/"}>
                    <img src={Logo} alt="Logo" />
                </Link>
                <div className={Styles["container"]}>
                    <div className={Styles["menu-open"]} onClick={toggleMenu}>
                        <span className={Styles["open"]}></span>
                    </div>
                    <div
                        className={`${Styles["ozmenu"]}  ${
                            menuActive ? Styles["active"] : ""
                        }`}>
                        <div
                            className={Styles["menu-close"]}
                            onClick={toggleMenu}>
                            <span className={Styles["close"]}></span>
                        </div>
                        <ul className={Styles["ozmenu-nav"]}>
                            <MenuItem label="Home" LinkTo="/" />
                            <MenuDropdown
                                label="What we do"
                                isOpen={openedDropdowns.includes(0)}
                                onClick={() => handleDropdownClick(0)}
                                items={[
                                    {
                                        label: "Business Consulting",
                                        LinkTo: "/Business-consulting",
                                    },
                                    {
                                        label: "Digital Transformation",
                                        LinkTo: "/digital-transformation",
                                    },
                                    {
                                        label: "Staffing",
                                        LinkTo: "/customer-experience",
                                    },
                                    {
                                        label: "Cloud",
                                        LinkTo: "/cloud",
                                    },
                                    {
                                        label: "Data & Analytics",
                                        LinkTo: "/data-analytics",
                                    },
                                    {
                                        label: "AI",
                                        LinkTo: "/AI",
                                    }
                                ]}
                            />
                            {/*     <MenuDropdown
                                label="Industries"
                                isOpen={openedDropdowns.includes(0)}
                                onClick={() => handleDropdownClick(0)}
                                items={[
                                    {
                                        label: "Retail",
                                        LinkTo: "/retail",
                                    },

                                    {
                                        label: "manufacturing and distrubution",
                                        LinkTo: "/manufacturing-distrubtion",
                                    },
                                ]}
                            /> */}
                            <MenuDropdown
                                label="Insights"
                                isOpen={openedDropdowns.includes(0)}
                                onClick={() => handleDropdownClick(0)}
                                items={[
                                    {
                                        label: "Digital Natives",
                                        LinkTo: "/Digital-natives",
                                    },
                                    {
                                        label: "Transformation & Innovation",
                                        LinkTo: "/transformation-and-innovation",
                                    },
                                    /*   {
                                        label: "Cloud Services",
                                        LinkTo: "/cloud-services",
                                    }, */
                                    /*   {
                                        label: "pharma And life sciences",
                                        LinkTo: "/data-analytics",
                                    }, */
                                ]}
                            />
                            <MenuDropdown
                                label="About Us"
                                isOpen={openedDropdowns.includes(0)}
                                onClick={() => handleDropdownClick(0)}
                                items={[
                                    {
                                        label: "Who we are",
                                        LinkTo: "/who-we-are",
                                    },
                                    /* {
                                        label: "Location",
                                        LinkTo: "/location",
                                    }, */

                                    /*    {
                                        label: "Diversity Equity & Inclusion",
                                        LinkTo: "/diversity-equity-inclusion",
                                    }, */
                                    {
                                        label: "News Room",
                                        LinkTo: "/news-room",
                                    },
                                    /* {
                                        label: "Leadership",
                                        LinkTo: "/leadership",
                                    }, */
                                    /*  {
                                        label: "Core values",
                                        LinkTo: "/core-values",
                                    }, */
                                ]}
                            />
                            <MenuItem label="Career" LinkTo="/career" />
                           <li className={Styles["syntLogoMob"]}>
                           <Link to={"https://synnthai.com/"}>
                           <img
                                src={Logo2}
                                alt=""                               
                            />
                            </Link>
                           </li>
                        </ul>
                    </div>
                    <div></div>
                    <div
                        id="menu-overlay"
                        className={mobileMenuActive ? Styles["show"] : ""}
                        onClick={toggleMenu}></div>
                </div>
            </div>
            <div className={Styles["content-wrapper"]}>
                {
                    <img
                        src={Logo2}
                        alt=""
                         onClick={() => window.open("https://synnthai.com/")}
                        style={{
                            marginLeft: "1rem",
                            width: "8rem",
                        }}
                    />
                }
                <button
                    className={Styles.talk}
                    onClick={() => Navigate("/Contact-us")}>
                    Let's Talk
                </button>
            </div>
        </div>
    );
};

const MenuItem: React.FC<MenuItemProps> = ({ label, LinkTo = "/" }) => (
    <li className={Styles["item"]}>
        <NavLink
            className={({ isActive }) => (isActive ? Styles["active"] : "")}
            to={LinkTo}>
            {label}
        </NavLink>
    </li>
);
const MenuItemButton: React.FC<ButtonTypes> = ({
    icon,
    Title,
    onClick,
    Theme,
    fontWeight,
}) => (
    <li className={Styles["item"]}>
        {/*  <a href="#">{label}</a> */}
        <Button
            Title={Title}
            onClick={onClick}
            Theme={Theme}
            icon={icon}
            fontWeight={fontWeight}
        />
    </li>
);

const MenuDropdown: React.FC<MenuDropdownProps> = ({
    label,
    isOpen,
    onClick,
    items,
}) => (
    <li className={`${Styles["item"]} ${Styles["dropdownitem"]}`}>
        <a
            // href="#"
            className={`${Styles["nav-dropdown"]} ${
                isOpen ? Styles["opened"] : ""
            }`}
            onClick={onClick}>
            {label}
        </a>
        {isOpen && (
            <div className={Styles["dropdown"]}>
                <ul>
                    {items.map((item, index) =>
                        item.subItems ? (
                            <MenuDropdown
                                key={index}
                                label={item.label}
                                isOpen={isOpen}
                                onClick={onClick}
                                items={item.subItems}
                            />
                        ) : (
                            <MenuItem
                                key={index}
                                label={item.label}
                                LinkTo={item.LinkTo}
                            />
                        )
                    )}
                </ul>
            </div>
        )}
    </li>
);

export default Menu;
