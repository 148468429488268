import React from "react";
import styled from "styled-components";

interface PropTypes {
    Title: string;
    onClick: (event: React.MouseEvent<HTMLButtonElement>) => void;
    Theme?: "Dark" | "Light";
    icon?: React.ReactNode;
    fontWeight?: number | string;
}

// Define the styled button outside of the component
const Button = styled.button<{
    themeType: "Dark" | "Light";
    fontWeight?: number | string;
}>`
    color: ${({ themeType }) => (themeType === "Light" ? "black" : "white")};
    background-color: ${({ themeType }) =>
        themeType === "Light" ? "white" : "#a63851"};
    border: ${({ themeType }) =>
        themeType === "Light" ? "1px solid #cccccc" : "1px solid #a63851"};
    padding: 0.4rem;
    border-radius: 10px;
    display:flex;
    align-items:center;
    gap:0.3rem;
    cursor: pointer;
       font-weight: ${({ fontWeight }) => fontWeight || "normal"};
    transition: all 0.3s ease-out;

   &:hover {
        background-position: ${({ themeType }) =>
            themeType === "Light"
                ? "100% 0"
                : "0 0"}; /* Reset to initial for light theme */
        color: ${({ themeType }) =>
            themeType === "Light" ? "white" : "black"};
        border-color: ${({ themeType }) =>
            themeType === "Light" ? "#a63851" : "#ccc"};
        background-color: ${({ themeType }) =>
            themeType === "Dark" ? "white" : "#a63851"};
    }
`;

const CustomButton: React.FC<PropTypes> = ({
    Title,
    onClick,
    Theme = "Light",
    fontWeight = "normal",
    icon,
}) => {
    return (
        <Button onClick={onClick} themeType={Theme} fontWeight={fontWeight}>
            <span style={{ marginTop: "0.2rem" }}> {icon}</span> {Title}
        </Button>
    );
};

export default CustomButton;
