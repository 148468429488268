import Hexgon from "Assets/HomePage/banner/hexagon.png";
import Hand from "Assets/HomePage/banner/hand.png";
import Money from "Assets/HomePage/banner/money.png";
import EarningClasses from "./earning.module.css";
import { useNavigate } from "react-router";
function Earning() {
    const Navigate = useNavigate();
    return (
        <div className={EarningClasses["Container"]}>
            {/* <img
                src={Hexgon}
                alt="Hexgon"
                className={EarningClasses["hexagon"]}
            /> */}
            {/* <img src={Hand} alt="" className={EarningClasses["Hand"]} /> */}
            {/* {<img src={Money} alt="" className={EarningClasses["coin"]} />} */}
            {/* </div> */}
            <h3>Get in touch now and start hiring</h3>
            <p>
                We’re dedicated to enhancing your business productivity and
                growth. Our team provides the support and solutions needed to
                keep your operations running smoothly and effectively.{" "}
            </p>
            <div className={EarningClasses["cta-btns"]}>
                <button onClick={() => Navigate("/Contact-us")}>
                    Contact US
                </button>
                {/* <button>Know More</button> */}
            </div>
        </div>
    );
}

export default Earning;
