import { FaPhoneVolume } from "react-icons/fa6";
import aboutclassNamees from "./About.module.css";
import Image1 from "Assets/jason-goodman-bzqU01v-G54-unsplash.jpg";
// import Image1 from "Assets/about_us_image.jpg";
import { GrTrophy } from "react-icons/gr";
import { TbTarget } from "react-icons/tb";
import { Gi3dStairs } from "react-icons/gi";
import ProfilePic from "Assets/profile-pic.png";
import Signature from "Assets/author-signature.png";
import { FaArrowRight } from "react-icons/fa";
import { useNavigate } from "react-router";
function About() {
    const Navigate = useNavigate();
    return (
        <div className={aboutclassNamees["wrapper"]}>
            <div className={aboutclassNamees["Container"]}>
                <div className={aboutclassNamees["about_img"]}>
                    <img src={Image1} alt="Image1" />
                </div>
                <div className={aboutclassNamees["right-side"]}>
                    {/* <h3>IT Staffing</h3> */}
                    <h1>
                        We assist organizations in building strong and effective
                        teams.
                    </h1>
                    {/*     <div className={aboutclassNamees["icon-container"]}>
                        <div className={aboutclassNamees["icon-wrapper"]}>
                            <div className={aboutclassNamees["icon"]}>
                                <TbTarget color="#1c4e73" />
                            </div>
                            <div>
                                <h3>Business Consultant</h3>
                                <p>
                                    Empowering businesses with strategic
                                    insights and solutions for sustainable
                                    growth.
                                </p>
                            </div>
                        </div>
                        <div className={aboutclassNamees["icon-wrapper"]}>
                            <div className={aboutclassNamees["icon"]}>
                                <Gi3dStairs color="#1c4e73" />
                            </div>
                            <div>
                                <h3>IT Staffing</h3>
                                <p>
                                    Providing top IT talent to drive your
                                    business forward with precision and
                                    expertise.
                                </p>
                            </div>
                        </div>
                    </div> */}
                    <p className={aboutclassNamees["last"]}>
                        We connect businesses in the US and India with skilled
                        IT professionals tailored to their specific needs,
                        ensuring seamless integration into their teams. Our
                        expert staffing solutions help you achieve your project
                        goals efficiently and effectively across both regions.
                    </p>
                    <div className={aboutclassNamees["ceo"]}>
                        {/* <img src={ProfilePic} alt="" />
                        <div>
                            <img src={Signature} alt="" />
                            <p>
                                Maritinaze, <span>CEO</span>
                            </p>
                        </div> */}
                        <button
                            onClick={() => Navigate("/customer-experience")}>
                            Read More <FaArrowRight />
                        </button>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default About;
