import React from "react";
import './styles.min.css'; // Use your existing min.css or add extra styles below
import { FaFingerprint } from "react-icons/fa";

const BusinessTransformation = () => {
  return (
    <div className="business-transformation">
      <div className="green__box" />
      <div className="text-section">
        <h1>Ready to Transform <br />Your <span>Business?</span></h1>
        <p>
          Discover Excellence in Data Solutions and create scalable business
          systems that drive growth consistently over time.
        </p>
        <button className="cta-button">
            <FaFingerprint />
          <div>
            Time to Data Up
          </div>
        </button>
      </div>

      {/* <div className="curve-section">
        <div className="milestone" style={{ top: "70%", left: "10%" }}>
          <span className="milestone-number">1</span>
          <p>Migration and Consolidation</p>
        </div>
        <div className="milestone" style={{ top: "60%", left: "30%" }}>
          <span className="milestone-number">2</span>
          <p>Democratizing Data</p>
        </div>
        <div className="milestone" style={{ top: "45%", left: "50%" }}>
          <span className="milestone-number">3</span>
          <p>Data-Driven Outcomes</p>
        </div>
        <div className="milestone" style={{ top: "30%", left: "70%" }}>
          <span className="milestone-number">4</span>
          <p>Data Marketplace & Collaboration</p>
        </div>
        <div className="arrow-container">
          <div className="arrow"></div>
        </div>
      </div> */}
    </div>
  );
};

export default BusinessTransformation;
