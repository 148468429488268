import React from "react";
import "react-responsive-carousel/lib/styles/carousel.min.css"; // requires a loader
import styles from "./index.module.css";
import { Carousel } from "react-responsive-carousel";
import { TypeAnimation } from "react-type-animation";
// import Video from "";
function index() {
  return (
    <div className={styles["main"]}>
      <Carousel
        // autoPlay
        showArrows={false}
        showStatus={false}
        // showThumbs={false}
        // showIndicators={false}
        infiniteLoop
      >
        <div className={styles["video"]}>
          <div className={styles["Container"]} style={{ left: "35%" }}>
            <div className={styles["blur-background"]}></div>
            <header>
              {/* <h3>WE ARE EXPERT IN THIS FIELD</h3> */}
              {/* <h1>Intelligent Staffing </h1> */}
              {/* <p>
                                Maximum Cloud solutions Helps You To Convert
                                Your Data Into A Strategic Asset And Get
                                Business Insights Apexa Helps You To Convert
                                Your Data Into Asset And Get Top-Notch Business
                                Insights.
                            </p> */}
              {/* <button>
                                Read More <FaArrowRight />
                            </button> */}
            </header>
          </div>
          {/* <img
                        src={Watermark}
                        alt=""
                        className={styles["watermark"]}
                    /> */}
          <video
            autoPlay
            muted
            playsInline
            loop
            className={styles["video-player"]}
          >
            <source src="/movie4.mp4" type="video/mp4" />
            Your browser does not support the video tag.
          </video>
        </div>

        <div className={styles["video"]}>
          <video
            autoPlay
            muted
            playsInline
            loop
            className={styles["video-player"]}
          >
            <source src="/video1.mp4" type="video/mp4" />
            Your browser does not support the video tag.
          </video>
        </div>
        <div className={styles["video"]}>
          <div
            className={styles["Container"]}
            style={{ bottom: "10%", top: "unset", left: "19%" }}
          >
            <div className={styles["blur-background"]}></div>
            <header>
              <h1>Artifical Intelligence </h1>
              {/* <p>
                                Maximum Cloud solutions Helps You To Convert
                                Your Data Into A Strategic Asset And Get
                                Business Insights Apexa Helps You To Convert
                                Your Data Into Asset And Get Top-Notch Business
                                Insights.
                            </p> */}
              {/* <button>
                                Read More <FaArrowRight />
                            </button> */}
            </header>
          </div>
          <video
            autoPlay
            muted
            playsInline
            loop
            className={styles["video-player"]}
          >
            <source src="/video2.mp4" type="video/mp4" />
            Your browser does not support the video tag.
          </video>
        </div>
      </Carousel>
      <div className={styles["animated__text"]}>
        <p>
          #1 Trusted Partner with Services<br />
          <TypeAnimation
            sequence={[
              "Business Consulting",
              1000, // wait 1s before replacing "Mice" with "Hamsters"
              "Digital Transformation",
              1000,
              "Staffing",
              1000,
              "Cloud",
              1000,
              "Data & Analtytics",
              1000,
              "AI",
              1000,
            ]}
            wrapper="span"
            speed={50}
            className="type__animation"
            repeat={Infinity}
          />
        </p>
      </div>
    </div>
  );
}

export default index;
