import React from "react";
import OfferClasses from "./Offer.module.css";
import { BsFillSuitcaseLgFill } from "react-icons/bs";
import { HiClipboardList } from "react-icons/hi";
import { SiCashapp } from "react-icons/si";
import { TbCopy } from "react-icons/tb";
import { FaArrowRight } from "react-icons/fa";
import { useNavigate } from "react-router";
import Card1 from "Assets/Busioness consulting card.jpg";
import Card2 from "Assets/IT STAFFING_11zon.jpg";
import Card3 from "Assets/snowflakenew.jpg";
function Offer() {
    const Navigate = useNavigate();
    let Cards = [
        {
            Title: "Business Consulting",
            Content:
                "Drive growth with tailored strategies and expert insights.",
            Icon: <BsFillSuitcaseLgFill color="#141763" />,
            Nav: "/Business-consulting",
            img: Card1,
        },
        {
            Title: "IT Staffing",
            Content:
                "Connect with top IT talent for seamless integration and project success.",
            Icon: <HiClipboardList color="#141763" />,
            Nav: "/customer-experience",
            img: Card2,
        },
        {
            Title: "Snowflake",
            Content:
                "For snowflake services you need to visit  Synthlake website  click here to visit.",
            Icon: <SiCashapp color="#141763" />,
            Nav: "https://synnthai.com",
            img: Card3,
        },
    ];
    return (
        <div className={OfferClasses["Container"]}>
            <header>
                <h3>What we Offer</h3>
                <h1>We Offer An Effective Wide Area Business solutions</h1>
            </header>
            <div className={OfferClasses["Cards"]}>
                {Cards.map((item, index) => (
                    <div className={OfferClasses["Card"]}>
                        <div>
                            <img src={item.img} alt="" />
                        </div>
                        <h3>{item.Title}</h3>
                        <p>{item.Content}</p>
                        <button
                            onClick={() => {
                                if (index == 2) {
                                    window.open(item.Nav, "_blank");
                                } else {
                                    Navigate(item.Nav);
                                }
                            }}>
                            {item.Title == "Snowflake"
                                ? "Click here"
                                : "Read More"}{" "}
                            <FaArrowRight color="#191d88" />
                        </button>
                    </div>
                ))}
            </div>
        </div>
    );
}

export default Offer;
