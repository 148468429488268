import { FaPhoneVolume } from "react-icons/fa6";
import aboutclassNamees from "./hero.module.css";
import Image1 from "Assets/about_us_image.jpg";
import AboutImg from 'Assets/Services-Select.png'

import { FaArrowRight } from "react-icons/fa";
import { useNavigate } from "react-router";
function About() {
  const Navigate = useNavigate();
  return (
    <div className={aboutclassNamees["wrapper"]}>
      <div className={aboutclassNamees["Container"]}>
        <div className={aboutclassNamees["right-side"]}>
          <h3>We Are Now</h3>
          <h1>
            Snowflake <span>Service Partner</span>
          </h1>
          <p className={aboutclassNamees["last"]}>
          We are pleased to announce that Maximum Cloud Solutions has received an upgrade to partner status with Snowflake Services. This accomplishment is a result of our team's unwavering dedication to striving for perfection and going above and beyond to help our clients succeed on the Snowflake AI Data Cloud Platform.
<br />
<br />
See the Snowflake solutions offered by Maximum.
          </p>
          {/* <div className={aboutclassNamees["ceo"]}>
            <button onClick={() => Navigate("/customer-experience")}>
              Learn More
            </button>
          </div> */}
        </div>
        <div className={aboutclassNamees["about_img"]}>
          <img src={AboutImg} alt="Image1" />
        </div>
      </div>
    </div>
  );
}

export default About;
