import Layout from "Components/Layouts/Common/common";
import Sectionone from "Components/Home/Section/Section";
import WhyChooseUs from "Components/Home/WhyChooseUs";
import About from "Components/Home/About/about";
import ScrollToTop from "react-scroll-to-top";
import { IoIosArrowUp } from "react-icons/io";
import Earning from "Components/Home/Earning/earning";
import Values from "Components/Home/Values/values";
import Stats from "Components/Home/Stats/Stats";
import Offer from "Components/Home/Offer/Offer";
import earning_icon from "Assets/HomePage/stats/earning.png";
import investment from "Assets/HomePage/stats/invesment.png";
import join from "Assets/HomePage/stats/join.png";
import team from "Assets/HomePage/stats/team.png";
import Blog from "Components/Home/Blogs/Blogs";
import Carousel from "Components/Home/Carousel/index";
import Banner from "Components/Home/Banner/banner";
import Hero from "Components/Home/hero/hero";
import BusinessTransformation from "Components/Home/readyToTransform/hero";

function Homepage() {
    const Data = [
        { icon: join, Count: 12, Heading: "Projects" },
        { icon: team, Count: 50, Heading: "Trusted Clients " },
        {
            icon: earning_icon,
            Count: 19,
            Heading: "Available Worldwide",
        },
    ];

    return (
        <Layout>
            <ScrollToTop
                className="Scroll-to-top"
                smooth
                // color=""
                component={<IoIosArrowUp color="white" size={28} />}
                style={{ borderRadius: "10px", backgroundColor: "#a63851" }}
            />
            <Carousel />
            {/* <Sectionone /> */}
            <Hero />
            <BusinessTransformation />
            <About />
            <Offer />
            <Earning />
            {/* <Values /> */}
            {/* <Banner /> */}
            <Blog />
            {/* <WhyChooseUs /> */}
            <Stats Data={Data} />
        </Layout>
    );
}

export default Homepage;
